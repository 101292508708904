<template>
  <section class="help--desk-wrapper">
    <div v-if="loggedIn">
      <div class="title--text">
        {{ $t('home.coRenting.homeheader1') }} {{ $t('home.coRenting.homeheader2') }}
      </div>
      <co-renting-form />
    </div>
    <div v-else>
      <div class="title--text">
        <span>{{ $t('home.managerSection.corentingLogin1') }} </span>
        <span class="color--primary" style="cursor: pointer" @click="showLogin">{{
          $t('loginRegister.login')
        }}</span>
        <span> {{ $t('home.managerSection.corentingLogin2') }}</span>
      </div>
      <div class="subtitle--text">
        {{ $t('home.managerSection.corentingLogMessage') }}
      </div>
    </div>
  </section>
</template>

<script>
import CoRentingForm from '@/components/help-desk/co-renting/co-renting-form';

export default {
  components: {
    CoRentingForm,
  },
  computed: {
    loggedIn() {
      return this.$store.state.global.loggedIn;
    },
  },
  methods: {
    showLogin() {
      this.$modal.show('modal-login');
    },
  },
};
</script>
