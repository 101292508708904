<template>
  <div>
    <page-loading :show="loading" />
    <form @submit.prevent="submitRequest">
      <div class="subtitle--text">
        {{ $t('home.coRenting.personal') }}
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('fullname') }">
        <div class="left--col">
          <label for="name">
            <span>{{ $t('profile.name') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input class="basic--input" id="name" type="text" v-model="fullname" />
          <span class="val-error" v-if="validation.hasError('fullname')">{{
            validation.firstError('fullname')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
        <div class="left--col">
          <label for="email">
            <span>{{ $t('loginRegister.email') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input class="basic--input" id="email" type="text" v-model="email" />
          <span class="val-error" v-if="validation.hasError('email')">{{
            validation.firstError('email')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
        <div class="left--col">
          <label for="phone">
            <span>{{ $t('profile.phone') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            id="phone"
            v-model="phone"
            :options="phoneCleaveOption"
            class="basic--input"
            type="text"
            name="phoneNumber"
          />
          <span class="val-error" v-if="validation.hasError('phone')">{{
            validation.firstError('phone')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('age') }">
        <div class="left--col">
          <label for="age">
            <span>{{ $t('profile.age') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            class="basic--input"
            type="number"
            id="age"
            v-model="age"
            :options="ageCleaveOption"
            ref="age"
          />
          <span class="val-error" v-if="validation.hasError('age')">{{
            validation.firstError('age')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('gender') }">
        <div class="left--col">
          <label for="gender">
            <span>{{ $t('profile.gender') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="gender"
            v-model="gender"
            select-label=""
            deselect-label=""
            name="gender"
            track-by="id"
            label="name"
            :options="genders"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('gender')">{{
            validation.firstError('gender')
          }}</span>
        </div>
      </div>
      <div class="subtitle--text">
        {{ $t('home.coRenting.preference') }}
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('gender') }">
        <div class="left--col">
          <label for="gender_preference">
            <span>{{ $t('profile.gender') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="gender_preference"
            v-model="gender"
            select-label=""
            deselect-label=""
            name="gender"
            track-by="id"
            label="name"
            :options="genders"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('gender')">{{
            validation.firstError('gender')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('min_age') }">
        <div class="left--col">
          <label for="min_age">
            <span>{{ $t('home.coRenting.min_age') }}</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            class="basic--input"
            type="number"
            id="min_age"
            v-model="min_age"
            :options="ageCleaveOption"
            ref="min_age"
          />
          <span class="val-error" v-if="validation.hasError('min_age')">{{
            validation.firstError('min_age')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('max_age') }">
        <div class="left--col">
          <label for="max_age">
            <span>{{ $t('home.coRenting.max_age') }}</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            class="basic--input"
            type="number"
            id="max_age"
            v-model="max_age"
            :options="ageCleaveOption"
            ref="max_age"
          />
          <span class="val-error" v-if="validation.hasError('max_age')">{{
            validation.firstError('max_age')
          }}</span>
        </div>
      </div>
      <div class="subtitle--text">
        {{ $t('general.overviewCorenting') }}
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('move_in_date') }">
        <div class="left--col">
          <label for="move_in_date">
            <span>{{ $t('general.startFrom') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <client-only>
            <date-picker
              id="move_in_date"
              v-model="move_in_date"
              :minDate="new Date()"
              :disabledDates="disabledDates"
              format="dd/MM/yyyy"
            ></date-picker>
          </client-only>
          <span class="val-error" v-if="validation.hasError('move_in_date')">{{
            validation.firstError('move_in_date')
          }}</span>
        </div>
      </div>
      <div
        class="form--group"
        v-if="formattedPropertyTypes && formattedPropertyTypes.length > 0"
        :class="{ 'has-error': validation.hasError('property_type_id') }"
      >
        <div class="left--col">
          <label for="property_type_id">
            <span>{{ $t('general.propertyType') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="property_type_id"
            v-model="property_type_id"
            select-label=""
            deselect-label=""
            name="property_type_id"
            track-by="id"
            label="name"
            :options="formattedPropertyTypes"
            :clear-on-select="false"
            :allow-empty="false"
            @input="propertyTypeChanged"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('property_type_id')">{{
            validation.firstError('property_type_id')
          }}</span>
        </div>
      </div>
      <div
        class="form--group"
        v-show="rentPeriods && rentPeriods.length > 0"
        :class="{
          'has-error':
            validation.hasError('length_of_stay') || validation.hasError('rent_period_id'),
        }"
      >
        <div class="left--col">
          <label>
            <span>{{ $t('general.filter.duration') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <div>
            <cleave
              class="basic--input"
              type="number"
              name="length_of_stay"
              v-model="length_of_stay"
              :options="lengthOfStayCleaveOption"
              ref="length_of_stay"
            />
            <span class="val-error" v-if="validation.hasError('length_of_stay')">{{
              validation.firstError('length_of_stay')
            }}</span>
          </div>
          <div class="mt-4">
            <multiselect
              id="rent_period_id"
              v-model="rent_period_id"
              select-label=""
              deselect-label=""
              name="rent_period_id"
              track-by="id"
              label="name"
              :options="rentPeriods"
              :allow-empty="false"
              @input="rentPeriodChanged"
            >
            </multiselect>
            <span class="val-error" v-if="validation.hasError('rent_period_id')">{{
              validation.firstError('rent_period_id')
            }}</span>
          </div>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('min_budget') }">
        <div class="left--col">
          <label for="min_budget">
            <span>{{ $t('home.coRenting.min_budget') }}</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            class="basic--input"
            type="text"
            id="min_budget"
            v-model="min_budget"
            :options="budgetCleaveOption"
            ref="min_budget"
          />
          <span class="val-error" v-if="validation.hasError('min_budget')">{{
            validation.firstError('min_budget')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('max_budget') }">
        <div class="left--col">
          <label for="max_budget">
            <span>{{ $t('home.coRenting.max_budget') }}</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            class="basic--input"
            type="text"
            id="max_budget"
            v-model="max_budget"
            :options="budgetCleaveOption"
            ref="max_budget"
          />
          <span class="val-error" v-if="validation.hasError('max_budget')">{{
            validation.firstError('max_budget')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('location') }">
        <div class="left--col">
          <label for="location">
            <span>{{ $t('general.location') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input type="text" id="location" class="basic--input" v-model="location" />
          <span class="val-error" v-if="validation.hasError('location')">{{
            validation.firstError('location')
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-center">
        <button type="submit" class="btn btn-primary">SEND</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

const DatePicker = () => import('@/components/utils/date-picker');
const PageLoading = () => import('@/components/content-loading/page-loading');
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
export default {
  name: 'CorporateClientForm',
  mixins: [HelperMixin],
  components: {
    Multiselect,
    Cleave,
    DatePicker,
    PageLoading,
  },
  computed: {
    ...mapGetters({
      formattedPropertyTypes: 'v2/corentingRequest/formattedPropertyTypes',
      rentPeriods: 'v2/corentingRequest/filteredRentPeriods',
    }),
    fullname: {
      get() {
        return this.$store.state.v2.corentingRequest.fullname;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_FULLNAME', val);
      },
    },
    email: {
      get() {
        return this.$store.state.v2.corentingRequest.email;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_EMAIL', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.v2.corentingRequest.phone;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_PHONE', val);
      },
    },
    age: {
      get() {
        return this.$store.state.v2.corentingRequest.age;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_AGE', val);
      },
    },
    gender: {
      get() {
        return this.$store.state.v2.corentingRequest.gender;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_GENDER', val);
      },
    },
    gender_preference: {
      get() {
        return this.$store.state.v2.corentingRequest.genderPreference;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_GENDER_PREFERENCE', val);
      },
    },
    min_age: {
      get() {
        return this.$store.state.v2.corentingRequest.minAgePreference;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_MIN_AGE_PREFERENCE', val);
      },
    },
    max_age: {
      get() {
        return this.$store.state.v2.corentingRequest.maxAgePreference;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_MAX_AGE_PREFERENCE', val);
      },
    },
    move_in_date: {
      get() {
        return this.$store.state.v2.corentingRequest.startDate;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_START_DATE', val);
      },
    },
    property_type_id: {
      get() {
        return this.$store.state.v2.corentingRequest.propertyType;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_PROPERTY_TYPE', val);
      },
    },
    length_of_stay: {
      get() {
        return this.$store.state.v2.corentingRequest.lengthOfStay;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_LENGTH_OF_STAY', val);
      },
    },
    rent_period_id: {
      get() {
        return this.$store.state.v2.corentingRequest.rentPeriod;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_RENT_PERIOD', val);
      },
    },
    min_budget: {
      get() {
        return this.$store.state.v2.corentingRequest.minBudget;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_MIN_BUDGET', val);
      },
    },
    max_budget: {
      get() {
        return this.$store.state.v2.corentingRequest.maxBudget;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_MAX_BUDGET', val);
      },
    },
    location: {
      get() {
        return this.$store.state.v2.corentingRequest.location;
      },
      set(val) {
        this.$store.commit('v2/corentingRequest/SET_LOCATION', val);
      },
    },
  },
  data: () => ({
    loading: false,
    phoneCleaveOption: {
      phone: true,
      phoneRegionCode: 'id',
    },
    ageCleaveOption: {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalMark: '',
      delimiter: '.',
      numeralIntegerScale: 3,
      numeralDecimalScale: 0,
    },
    lengthOfStayCleaveOption: {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalMark: '',
      delimiter: '.',
      numeralIntegerScale: 2,
      numeralDecimalScale: 0,
    },
    budgetCleaveOption: {
      prefix: 'Rp ',
      numeral: true,
      numeralPositiveOnly: true,
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralIntegerScale: 16,
      numeralDecimalScale: 0,
    },
    genders: [
      {
        id: 'L',
        name: 'Pria',
      },
      {
        id: 'P',
        name: 'Wanita',
      },
    ],
    gender_preferences: [
      {
        id: 'SAME',
        name: 'Jenis Kelamin Sama',
      },
      {
        id: 'MIXED',
        name: 'Jenis Kelamin Campuran',
      },
      {
        id: 'NONE',
        name: 'Tidak Ada Preferensi',
      },
    ],
    disabledDates: [],
  }),
  validators: {
    fullname(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.fullName.required'))
        .maxLength(191, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 191 }));
    },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .maxLength(191, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 191 }))
        .email();
    },
    phone(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.phone.required'))
        .maxLength(191, this.$i18n.t('errors.phone.max', { maxChar: 191 }));
    },
    age(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.age.required'))
        .maxLength(10, this.$i18n.t('errors.age.max', { maxChar: 10 }));
    },
    min_age(value) {
      return Validator.value(value).maxLength(
        10,
        this.$i18n.t('errors.min_age.max', { maxChar: 10 }),
      );
    },
    max_age(value) {
      return Validator.value(value).maxLength(
        10,
        this.$i18n.t('errors.max_age.max', { maxChar: 10 }),
      );
    },
    gender(value) {
      return Validator.value(value).required(this.$i18n.t('errors.gender.required'));
    },
    move_in_date(value) {
      return Validator.value(value).required(this.$i18n.t('errors.startDate.required'));
    },
    property_type_id(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.propertyType.required'),
      );
    },
    length_of_stay(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.booking.duration.required'))
        .maxLength(20, this.$i18n.t('errors.booking.duration.max', { maxChar: 20 }));
    },
    rent_period_id(value) {
      return Validator.value(value).required(this.$i18n.t('errors.rent_period.required'));
    },
    min_budget(value) {
      return Validator.value(value).maxLength(
        20,
        this.$i18n.t('errors.min_budget.max', { maxChar: 20 }),
      );
    },
    max_budget(value) {
      return Validator.value(value).maxLength(
        20,
        this.$i18n.t('errors.max_budget.max', { maxChar: 20 }),
      );
    },
    location(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.location.required'))
        .maxLength(100, this.$i18n.t('errors.location.max', { maxChar: 100 }));
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.validation.reset();
      let data = await this.$store.dispatch('v2/corentingRequest/getData');
      this.fullname = data.fullname;
      this.email = data.email;
      this.phone = data.phone;
      // eslint-disable-next-line no-empty
    } catch (_) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async submitRequest() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid) {
          const result = await this.$store.dispatch('v2/corentingRequest/postRequest');
          if (result) {
            this.$swal(
              this.$t('home.coRenting.swal.success'),
              this.$t('home.coRenting.swal.successMessage'),
              'success',
            );
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    propertyTypeChanged(propertyType) {
      this.$store.dispatch('v2/corentingRequest/changePropertyType', propertyType.id);
    },
    rentPeriodChanged(rentPeriod) {
      this.$store.commit('v2/corentingRequest/SET_SELECTED_RENT_PERIOD', rentPeriod.id);
    },
  },
};
</script>
