<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <co-renting />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import CoRenting from '@/components/help-desk/co-renting';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  components: {
    DefaultLayout,
    ViewSkeleton,
    CoRenting,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.corenting'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    let title = '';
    let description = '';
    title = 'Co-Renting Rentfix.com - Platform Sewa Properti di Indonesia';
    description =
      'Registrasi untuk mendapatkan Co-Renting di Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
};
</script>
